import { createSlice } from '@reduxjs/toolkit'
import { API_INQUIRES } from 'src/api'
import { del } from 'src/utils/requests'
import { message } from 'antd'

const initialState = {
  id: '',
  name: '',
  description: '',
  expire_at: '',
  budget: 0,
  currency: null,
  show_minimum_price: false,
  is_active: false,
  open: false,
  finished: false,
  local: false,
  delivery_time: null,
  delivery_term: '',
  payment_condition: '',
  inquireitem_set: [],
  participants: [],
  groups: [],
  inquiredocument_set: [],
  assigned_to: null,
  assigned_to_label: null,
  show_contact: false,
  errors: {},
}

const inquireSlice = createSlice({
  name: 'inquire',
  initialState,
  reducers: {
    setValue(state, action) {
      Object.keys(action.payload).forEach((key) => {
        if (key in state.errors) {
          delete state.errors[key as keyof typeof state.errors]
        }
      })
      Object.assign(state, action.payload)
    },
    reset(state, action) {
      return { ...initialState }
    },
    deleteItem(state, action) {
      const { id } = action.payload
      del(`${API_INQUIRES}${id}/`)
        .then((res: any) => {
          message.success(JSON.stringify(res?.statusText))
          // navigate('/products/')
          window.location.href = '/inquires/'
        })
        .catch((err: any) => {
          // setAlert(true)
        })
    },
    moveLotUp(state, action) {
      let lots = JSON.parse(JSON.stringify(state.inquireitem_set))
      const topLot = lots[action.payload - 1]
      const currentLot = lots[action.payload]
      lots[action.payload] = topLot
      lots[action.payload - 1] = currentLot
      state.inquireitem_set = lots
    },
    moveLotDown(state, action) {
      let lots = JSON.parse(JSON.stringify(state.inquireitem_set))
      const bottonLot = lots[action.payload + 1]
      const currentLot = lots[action.payload]
      lots[action.payload] = bottonLot
      lots[action.payload + 1] = currentLot
      state.inquireitem_set = lots
    },
    addLot(state) {
      let lots = JSON.parse(JSON.stringify(state.inquireitem_set))
      lots.push({})
      state.inquireitem_set = lots
    },
    removeLot(state, action) {
      let lots = JSON.parse(JSON.stringify(state.inquireitem_set))
      const newLots = lots.filter((_item: number, index: number) => index !== action.payload)
      state.inquireitem_set = newLots
    },
    updateLot(state, action) {
      let lots = JSON.parse(JSON.stringify(state.inquireitem_set))
      lots[action.payload.index] = action.payload.value
      state.inquireitem_set = lots
    },
    addDocuments(state, action) {
      const files = action.payload
      let docs = JSON.parse(JSON.stringify(state.inquiredocument_set))
      for (let i = 0; i < files.length; i++) {
        docs.push({
          id: null,
          // TODO -> Set document path to file object
          document: files[i].name,
          name: files[i].name,
          // TODO -> Set url
          url: URL.createObjectURL(files[i]),
          size: files[i].size,
        })
      }
      // alert(JSON.stringify(docs))
      state.inquiredocument_set = docs
    },
    removeDocument(state, action) {
      let lots = JSON.parse(JSON.stringify(state.inquiredocument_set))
      const newLots = lots.filter((_item: number, index: number) => index !== action.payload)
      state.inquiredocument_set = newLots
    },
  },
})

export const {
  setValue,
  reset,
  deleteItem,
  moveLotUp,
  moveLotDown,
  addLot,
  removeLot,
  updateLot,
  addDocuments,
  removeDocument,
} = inquireSlice.actions
export default inquireSlice.reducer
